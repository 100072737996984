import React, { useState } from "react";    
import "../Styles/Team.css";
import TeamCard from "../components/TeamCard";
import teamData from "../data/teamdata.json";

export default function TeamPage() {
    
    const teamTypes = [
        {
            teamId:"core",teamName:"Core"
        },
        {
            teamId:"dev",teamName:"Development"
        }
        
    ]

  const [showTeam, setShowTeam] = useState("core");

  return (
    <div className="Team-body">
      <h1 className="text-white p-5 text-center w-full text-6xl font-medium mt-10">Our Team</h1>
      <div className="Team-row">
        {teamTypes.map((val, idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                setShowTeam(val.teamId);
              }}
              className={"Team-btn" + (showTeam === val.teamId ? " active" : "")}
            >
              {val.teamName}
            </button>
          );
        })}
      </div>
      <div className="Team-row">
        {teamData.team[showTeam].map((val, idx) => (
          <TeamCard
            key={idx}
            img={require(`../../public/photos/${val.image}`)}
            name={val.name}
            designation={val.designation}
            // quote={val.quote}
            linkedin={val.linkedin}
          />
        ))}
      </div>
    </div>
  );
}
