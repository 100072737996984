  import React from 'react';
  import './About.css';
  import a3 from '../images/a3.png';
  import a4 from '../images/a4.png';
  import RegisterButton from "../components/RegisterButton";
  const aboutus = () => {
    return (
      <div className="bg-gradient-to-t from-indigo-800 py-8 relative mt-5">
        <div className="container mx-auto px-4 md:flex md:items-center flex-col">
          <div className="animate-fadeIn backdrop-grayscale-[.45] backdrop-brightness-1 shadow-lg p-8 mb-8 rounded-2xl relative">
            <h2 className="text-3xl font-bold mb-6 text-center text-gray-100">About Us</h2>
            <div className="flex mb-8 justify-center">
              <div className="mr-4 animate-slideInLeft">
                <img
                  src={a3}
                  alt="Company illustration"
                  className="rounded-lg shadow-md"
                />
              </div>
              <div className="animate-slideInRight">
                <img
                  src={a4}
                  alt="Company illustration"
                  className="rounded-lg shadow-md"
                />
              </div>
            </div>
            <div className="md:text-justify mx-auto animate-slideInUp mb-8 text-gray-300">
              <p className="mb-6">
              Tech Villa is first of its kind event in VIT Chennai, hosted by Zero Bugs Club. This is an open event where students from other colleges along with students from VIT Chennai take part in an umbrella of three simultaneous events happening for 2 days
              <ul className="list-disc list-inside">
                <li>Hackathon </li>
                <li>Workshop</li>
                <li>Entrepreneurship</li>
              </ul>
              </p>
              <p className="mb-6">
              This event provides an opportunity for innovation for entrepreneurs with innovative ideas. It serves as a platform for student entrepreneurs to start their entrepreneurship aspirations and provides opportunity to network with people form industry and gain experience
              </p>
              <p className="mb-6">
              The workshops are focused on the emergent technologies in the industry like Ethical Hacking and Artificial Intelligence. These focus on equipping students with practical skills required in current day competitive industry by exposing them to real world problems and providing hands on experience. The hackathon helps students in showcasing their coding skills and how they can contribute to the sectors like health, EV etc
              </p>
              <p className="">
              Zero Bugs Club is a student run club in VIT Chennai which consists of students interested in diverse backgrounds developing collaboration and innovation among members. We have successfully conducted Tech Trivia, Krypthon events which were a huge success.
              </p>
            </div>
            <div className="items-center justify-center flex">
              <RegisterButton />
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default aboutus;
