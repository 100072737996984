import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Workshops from './pages/WorkShops';
import Header from './components/Header';
import Comp from './pages/Comp';
import AboutUs from './pages/About';
import Sponsors from './pages/Sponsor';
import Timeline from './pages/Schedule';
import TeamPage from './pages/Team';
import { Footer } from './components/Footer';


function App() {
  return (
    <BrowserRouter>
      <Header/>
      <div className='mx-auto pt-[8vh]'>
      <Routes>
        <Route path='/workshops' element={<Workshops/>}/>
        <Route path="/comp" element={<Comp/>}/>
        <Route path="/" element={<AboutUs/>}/>
        <Route path="/sponsors" element={<Sponsors/>}/>
        <Route path='/schedule' element={<Timeline/>}/>
        <Route path='/team' element={<TeamPage/>}/>
      </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
    
  );
}

export default App;
