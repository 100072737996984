import React from 'react';
import hackathon from '../images/Hackathon.png';
import entre from '../images/Entrepreneurship.png';

const Comp = () => {
  return (
    <>
      <div>
        <h1 className="text-white mb-5 p-5 text-center w-full text-6xl font-medium mt-10">Competitions</h1>
        <div className="flex justify-around flex-wrap">
          <div className="bg-gradient-to-t from-[#6045ea] lg:w-[650px] md:w-[625px] sm:w-[500px] h-auto lg:h-[300px] m-[30px] p-[20px] rounded-2xl text-white lg:hover:h-auto lg:transition-all lg:duration-500 ease-in-out group lg:group-hover:translate-y-0 lg:group-hover:h-auto">
            <img src={entre} alt="Logo here" className="w-[500px] h-[100px] md:h-[120px] m-auto md:w-[100%] lg:group-hover:h-[100px] lg:group-hover:transition-all duration-300" />
            <div>
              <h4 className="text-center text-3xl font-semibold mt-[3%] lg:group-hover:mt-[1%] lg:group-hover:transition-all duration-300">From the house of TechVilla</h4>
              <div className="items-center grid justify-items-center pt-[5%] lg:group-hover:pt-[2%] lg:group-hover:transition-all duration-300">
                <a href='https://www.vitchennaievents.com/conf1/index.php?eventid=1999' target="_blank" rel='noreferrer'>
                  <button className="text-center max-w-[300px] text-lg px-4 py-3 bg-slate-50 rounded text-black font-semibold">
                    View Details / Register Here
                  </button>
                </a>
                <p className="text-center text-[18px] m-2 lg:opacity-0 lg:overflow-hidden lg:group-hover:opacity-100 lg:transition-opacity pt-2">Interested in Entrepreneurship and have a great idea? <br />Dive into the world of Entrepreneurship with us by joining in an engaging event where you will be having a platform to place your idea on the table and secure funding.<br /> You will have a great opportunity to network with the industry leaders and kickstart your career in innovation and business development, all in just three stages</p>
              </div>
            </div>
          </div>
          <div className="bg-gradient-to-t from-[#6045ea] lg:w-[650px] md:w-[625px] sm:w-[500px] h-auto lg:h-[300px] m-[30px] p-[20px] rounded-2xl text-white lg:hover:h-auto lg:transition-all lg:duration-500 ease-in-out group lg:group-hover:translate-y-0 lg:group-hover:h-auto">
            <img src={hackathon} alt="Logo here" className="w-[500px] h-[100px] md:h-[120px] m-auto md:w-[66%] lg:group-hover:h-[100px] lg:group-hover:transition-all duration-300" />
            <div>
              <h4 className="text-center text-3xl font-semibold mt-[3%] lg:group-hover:mt-[1%] lg:group-hover:transition-all duration-300">From the house of TechVilla</h4>
              <div className="items-center grid justify-items-center pt-[5%] lg:group-hover:pt-[2%] lg:group-hover:transition-all duration-300">
                <a href='https://www.vitchennaievents.com/conf1/index.php?eventid=1994' target="_blank" rel='noreferrer' >
                  <button className="text-center max-w-[300px] text-lg px-4 py-3 bg-slate-50 rounded text-black font-semibold">
                    View Details / Register Here
                  </button>
                </a>
                {/* changer */}
                <p className="text-center text-[18px] m-2 lg:opacity-0 lg:overflow-hidden lg:group-hover:opacity-100 lg:transition-opacity pt-2">Are you a Tech - Savvy or a Coding Geek? <br />Join us for an exciting hackathon event where you will be having an opportunity to showcase your coding skills and develop innovative solutions to real-world problems. Students will be able to collaborate with like-minded people and contribute to the domains of EV, Health, Sustainability, Blockchain, and more <br />Winners are awarded cash prize & merit certificates </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Comp;
