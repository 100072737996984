import React, { useEffect, useState } from "react";
import greenTickImage from '../images/green-tick.png'
import { useNavigate } from "react-router-dom";
import supabase from "../utility/supabase";

function RegistrationForm({showModal,setShowModal}) {

    const navigate = useNavigate()
    const [formData,setFormData] = useState({
        name:'',
        regno:'',
        phno:'',
        email:''
    })

    const [modalData,setModalData] = useState(false)
    
    
    const handleChange = (e)=>setFormData({...formData,[e.target.name]:e.target.value})

    const handleSubmit = async(e) => {
        e.preventDefault();
        const {name,regno,phno,email} = formData;
        try{
            const {data,error} = await supabase
            .from('students')
            .insert([
                {name,regno,phno,email}
            ])
            setModalData(true)
        }catch(error){
            console.error("Error fetching data:", error.message);

        }
    };

    const redirectInfo=()=>{
        setTimeout(()=>{
            window.location.href = "https://vitchennaievents.com/";
        },3000);
    }

    useEffect(()=>{
    
        if(modalData){
            redirectInfo()
        }
    },[modalData]);

    return (
        <div className="z-[5]">
            {
                showModal && (

                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center px-4 z-[998]">
                        <div className="modal-content bg-gray-900 p-6 rounded-lg w-full max-w-md z-[999]">
                        <span className="close text-white text-2xl leading-none hover:text-gray-300 cursor-pointer float-right font-bold" onClick={() => setShowModal(false)}>&times;</span>
                        {
                            modalData ? (
                                <div className='flex flex-col justify-center'>
                                    <img src={greenTickImage} className={'w-[50%] h-auto text-center items-center m-auto'} alt="" />
                                    <h1 className={'text-white text-3xl text-center m-5'}>Thank you for Providing the Information</h1>
                                    <p className={'text-gray-300 text-center mt-5'}>Redirecting to vitchennaievents<br/>Please complete the registration</p>
                                </div>
                                
                            ):
                            <form onSubmit={handleSubmit} className={'text-white mt-4'}>
                                <h2 className={'text-center text-3xl font-bold my-3'}>Enter your Details</h2>
                                <div className={'mb-4'}>
                                    <label htmlFor="name" className="block text-gray-300 mb-2">Name</label>
                                    <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    required
                                    value={formData.name || ''}
                                    onChange={handleChange}
                                    className="w-full p-2 rounded bg-gray-800 text-white border border-gray-700 focus:border-blue-500"
                                    />
                                </div>
                                <div className={'mb-4'}>
                                    <label htmlFor="regno" className="block text-gray-300 mb-2">Registration Number</label>
                                    <input 
                                    type="text" 
                                    id="regno" 
                                    name="regno"    
                                    required
                                    value={formData.regno || ''}
                                    onChange={handleChange}
                                    className="w-full p-2 rounded bg-gray-800 text-white border border-gray-700 focus:border-blue-500"
                                    />
                                </div>
                                <div className={'mb-4'}>
                                    <label htmlFor="phno" className="block text-gray-300 mb-2">Phone Number</label>
                                    <input 
                                    type="number" 
                                    id="phno" 
                                    name="phno" 
                                    required
                                    value={formData.phno || ''}
                                    onChange={handleChange}
                                    className="w-full p-2 rounded bg-gray-800 text-white border border-gray-700 focus:border-blue-500 overflow-x-hidden"
                                    />
                                </div>
                                <div className={'mb-4'}>
                                    <label htmlFor="email" className="block text-gray-300 mb-2">Email Id</label>
                                    <input 
                                    type="email" 
                                    id="email" 
                                    name="email" 
                                    required
                                    value={formData.email || ''}
                                    onChange={handleChange}
                                    className="w-full p-2 rounded bg-gray-800 text-white border border-gray-700 focus:border-blue-500"
                                    />
                                </div>
                                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Submit
                                </button> 
                            </form>
                        }
                        
                        </div>
                    </div>
                )
            }

        </div>
    );
}

export default RegistrationForm;