export const ideathon_events=[
    {
        heading:"Event 1",
        subHeading:"Started project",
        direction:"right"
    },
    {
        heading:"Event 2",
        subHeading: "body",
        direction: "left"
    },
    {
        heading:"Event 3",
        subHeading: "Project expansion",
        direction:"right"
    },
    {
        heading:"Event 4",
        subHeading: "body",
        direction: "left"
    },
    {
        heading:"Event 5",
        subHeading: "body",
        direction: "right"
    },
    {
        heading:"Event 6",
        subHeading: "body",
        direction: "left"
    },
    {
        heading:"Event 7",
        subHeading: "body",
        direction: "right"
    }
]
