import React, { useState } from 'react'
import RegistrationForm from './RegistrationForm';

function RegisterButton(){
    const [showModal, setShowModal] = useState(false);
    return(
        <div>
            
            <button onClick={() => setShowModal(true)} className={"z-[5] px-4 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg md:ml-7 sm:ml-0"}>Register Now</button>        
            <RegistrationForm showModal={showModal} setShowModal={setShowModal}/>
    </div>
    )
};

export default RegisterButton;