import React from "react";
import hack from "../images/hack.jpg";
import ai from "../images/softb.jpg";
function Workshops(){
    return(
        <div className={'flex flex-col text-white justify-center my-10'}>
            <h1 className={'text-white mb-5 p-5 text-center w-full text-6xl font-medium mt-10'}>Workshops</h1>
            <div className={"flex flex-row sm:flex-col-1 max-w-[97%] max-md:max-w-[95%] justify-center text-center items-center m-auto bg-black bg-opacity-50 p-10 rounded"}>
                <img src={ai} className={'max-sm:hidden sm:w-[40%] md:w-[40%] lg:w-[20%] xl:w-[12%] img rounded-full'} alt="" />
                <div className={'flex flex-col items-center gap-4'}>
                    <h3 className={'text-3xl'}>AI/ML</h3>
                    <p className={'max-w-[90%] max-sm:max-w-[98%] text-justify text-[18px]'}>In the AI/ML segment, participants will explore the fascinating world of AI and ML technologies. From understanding the fundamentals to hands-on application, this session will cover:
                        <br></br><br></br>
                        Introduction to AI and ML: Learn the basics of AI and ML, including key concepts, algorithms, and applications.
                        <br></br><br></br>
                        Hands-on Projects: Engage in interactive projects to apply AI/ML techniques and algorithms in real-world scenarios.
                        <br></br><br></br>
                        Advanced Topics: Dive deeper into advanced topics such as deep learning, neural networks, natural language processing, and computer vision.
                        <br></br><br></br>
                        Key Takeaways:
                        <br></br>
                        Deep understanding of AI/ML principles and techniques.
                        <br></br>
                        Hands-on experience with AI/ML projects and applications.

                    </p>
                    <div className="block lg:hidden items-center grid justify-items-center pt-3">
                        <a href='https://www.vitchennaievents.com/conf1/index.php?eventid=1997' target="_blank" rel='noreferrer'>
                            <button className="text-center w-[179px] max-w-[300px] text-lg px-4 py-3 bg-slate-50 rounded text-black font-semibold">
                            Register Now
                            </button>
                        </a>
                    </div>
                </div>
                <div className="hidden lg:block items-center grid justify-items-center pt-3">
                    <a href='https://www.vitchennaievents.com/conf1/index.php?eventid=1998' target="_blank" rel='noreferrer'>
                    <button className="text-center w-[179px] max-w-[300px] text-lg px-4 py-3 bg-slate-50 rounded text-black font-semibold">
                    Register Now
                    </button>
                    </a>
                </div>
            </div>
            <div className={"flex flex-row sm:flex-col-1 max-w-[97%] max-md:max-w-[95%] justify-center text-center items-center m-auto mt-10 bg-black bg-opacity-50 p-10 rounded"}>
                <img src={hack} className={'max-sm:hidden sm:w-[40%] md:w-[40%] lg:w-[20%] xl:w-[12%] img rounded-full'} alt="" />
                <div className={'flex flex-col items-center gap-4'}>
                    <h3 className={'text-3xl'}>Ethical Hacking</h3>
                    <p className={'max-w-[90%] max-sm:max-w-[98%] text-justify text-[18px]'}>In the Ethical Hacking segment, participants will embark on a journey to understand and implement cybersecurity principles and ethical hacking techniques. This session will cover:
                        <br></br><br></br>
                        Fundamentals of Ethical Hacking: Gain insights into the ethical hacking landscape, including principles, methodologies, and tools.
                        <br></br><br></br>
                        Hands-on Labs: Explore ethical hacking techniques through practical, hands-on labs and simulations.
                        <br></br><br></br>
                        Cybersecurity Best Practices: Learn essential cybersecurity best practices and strategies to protect systems and data from cyber threats.
                        <br></br><br></br>
                        Key Takeaways:
                        <br></br>
                        Deep understanding of AI/ML principles and techniques.
                        <br></br>
                        Hands-on experience with AI/ML projects and applications.
                    </p>
                    <div className="block lg:hidden items-center grid justify-items-center pt-3">
                        <a href='https://www.vitchennaievents.com/conf1/index.php?eventid=1997' target="_blank" rel='noreferrer'>
                            <button className="text-center w-[179px] max-w-[300px] text-lg px-4 py-3 bg-slate-50 rounded text-black font-semibold">
                            Register Now
                            </button>
                        </a>
                    </div>
                </div>
                <div className="hidden lg:block items-center grid justify-items-center pt-3">
                    <a href='https://www.vitchennaievents.com/conf1/index.php?eventid=1997' target="_blank" rel='noreferrer'>
                    <button className="text-center w-[179px] max-w-[300px] text-lg px-4 py-3 bg-slate-50 rounded text-black font-semibold">
                    Register Now
                    </button>
                    </a>
                </div>
            </div>
            <div className={"flex flex-row sm:flex-col-1 w-[50%] max-md:w-[95%] justify-center text-center items-center my-20 m-auto rounded"}>
                <div className={'flex flex-col items-center gap-4'}>
                <h3 className={'text-6xl font-bold'}>Who Should Attend?</h3>
                    <p className={'max-w-[95%] max-sm:max-w-[98%] text-xl'}>
                        This workshop is ideal for technology enthusiasts, students, professionals, and anyone interested in exploring the exciting fields of AI/ML and Ethical Hacking. Whether you're a beginner or an experienced practitioner, there's something for everyone in this comprehensive workshop series.
                        <br></br><br></br>
                    </p>
                    <h3 className={'text-6xl font-bold mt-10'}>Join Us!</h3>
                    <p className={'max-w-[95%] max-sm:max-w-[98%] text-xl'}>
                        Don't miss this opportunity to expand your knowledge, sharpen your skills, and connect with industry experts in AI/ML and Ethical Hacking. Register now to secure your spot in our upcoming workshop series.
                    </p>
                </div>
            </div>
            {/* <div className={"flex flex-row sm:flex-col-1 max-w-[75%] max-md:max-w-[95%] justify-center text-center items-center m-auto mt-10 bg-black bg-opacity-50 p-10 rounded"}>
                <img src={img1} className={'max-sm:hidden w-20 md:w-[100%] lg:w-[60%] xl:w-[40%] img rounded-full'} alt="" />
                <div className={'flex flex-col items-center gap-4'}>
                    <h3 className={'text-3xl'}>Simulation</h3>
                    <p className={'max-w-[80%] max-sm:max-w-[98%] max-md:text-justify'}>Embark on a journey of simulation at OPTIMA! Step into the realm of virtual scenarios and immerse yourself in the technical intricacies of creating and running simulations. From designing lifelike environments to programming dynamic interactions, this hands-on session will unveil the power of simulation in modeling real-world phenomena. Join us to explore how simulations drive advancements in diverse fields, from engineering and healthcare to gaming and urban planning. Get ready to unlock your creativity and experience the magic of virtual worlds in this exciting workshop!</p>
                </div>
            </div>
            <div className={"flex flex-row sm:flex-col-1 max-w-[75%] max-md:max-w-[95%] justify-center text-center items-center m-auto mt-10 bg-black bg-opacity-50 p-10 rounded"}>
                <img src={img1} className={'max-sm:hidden w-20 md:w-[100%] lg:w-[60%] xl:w-[40%] img rounded-full'} alt="" />
                <div className={'flex flex-col items-center gap-4'}>
                    <h3 className={'text-3xl'}>Simulation</h3>
                    <p className={'max-w-[80%] max-sm:max-w-[98%] max-md:text-justify'}>Embark on a journey of simulation at OPTIMA! Step into the realm of virtual scenarios and immerse yourself in the technical intricacies of creating and running simulations. From designing lifelike environments to programming dynamic interactions, this hands-on session will unveil the power of simulation in modeling real-world phenomena. Join us to explore how simulations drive advancements in diverse fields, from engineering and healthcare to gaming and urban planning. Get ready to unlock your creativity and experience the magic of virtual worlds in this exciting workshop!</p>
                </div>
            </div>
            <div className={"flex flex-row sm:flex-col-1 max-w-[75%] max-md:max-w-[95%] justify-center text-center items-center m-auto mt-10 bg-black bg-opacity-50 p-10 rounded"}>
                <img src={img1} className={'max-sm:hidden w-20 md:w-[100%] lg:w-[60%] xl:w-[40%] img rounded-full'} alt="" />
                <div className={'flex flex-col items-center gap-4'}>
                    <h3 className={'text-3xl'}>Simulation</h3>
                    <p className={'max-w-[80%] max-sm:max-w-[98%] max-md:text-justify'}>Embark on a journey of simulation at OPTIMA! Step into the realm of virtual scenarios and immerse yourself in the technical intricacies of creating and running simulations. From designing lifelike environments to programming dynamic interactions, this hands-on session will unveil the power of simulation in modeling real-world phenomena. Join us to explore how simulations drive advancements in diverse fields, from engineering and healthcare to gaming and urban planning. Get ready to unlock your creativity and experience the magic of virtual worlds in this exciting workshop!</p>
                </div>
            </div> */}
        </div>
    )
}

export default Workshops;