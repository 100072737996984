
import {Bars3BottomRightIcon,XMarkIcon} from '@heroicons/react/24/solid'
import {useState} from 'react';
import { ReactComponent as ZbcBlack } from '../images/zbcblack.svg';
import RegisterButton from './RegisterButton';
const Header = () => {
    let Links =[
        {name: 'About', link: '/'},
        {name: 'Competitions', link: '/comp'},
        {name: 'Workshops', link: '/workshops'},
        {name: 'Team', link: '/team'},
        {name: 'Sponsors', link: '/sponsors'},
        // {name: 'Schedules', link: '/schedule'}
    ]

    let [isOpen, setisOpen] = useState(false);  
return (
    <div className="shadow-md w-full fixed top-0 z-[5]">
        <div className="md:px-10 py-4 px-7 md:flex justify-between items-center bg-white relative">
            {/*logo*/}
            <a href='/'>
                <div className="flex gap-2 text-2xl cursor-pointer items-center">
                        <ZbcBlack/>
                        {/*<img src="../assets/images/logo.png" alt="ZBC" className="w-7 h-7"/>*/}
                        <span className="font-bold">Tech Villa</span>
                </div>
            </a>
            <div onClick={() => setisOpen(!isOpen)} className="w-7 h-7 absolute right-8 top-8 curdor-pointer md:hidden">
                {
                    isOpen ? <XMarkIcon/> : <Bars3BottomRightIcon/>
                }
            </div>
            {/*nav links*/}
            <ul className={`md:flex pl-9 md:pl-0 md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-100 left-0 w-full md:w-auto transition-all bg-white duration-500 ease-in ${isOpen ? 'top-13' : 'top-[-490px]'}`}>
                {
                    Links.map(link => (
                    <li className="font-semibold text-lg my-7 md:my-0 md:ml-8">
                        <a href={link.link}>{link.name}</a>
                    </li>))
                }
                <RegisterButton/>
            </ul>
        </div>

    </div>
)
}

export default Header
