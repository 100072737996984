import React from "react";
import hack2skill from "../images/H2S-Gradient.png";
import se from '../images/se-crop.png'

function Sponsors() {
    return (
        <div>
            <div className={'flex flex-col text-white justify-center my-10'}>
                <h1 className={'text-7xl font-medium text-center mt-10'}>Sponsors</h1>
                <div className={'bg-black bg-opacity-40 w-[80%] items-center flex justify-center text-center m-auto mt-10 flex-col gap-10 p-10 rounded-lg'}>
                    <h2 className={'text-6xl font-bold'}>Techvilla - 2024</h2>
                    <h4 className={'text-5xl mt-[1%] font-semibold'}>Title Sponsor</h4>
                    <img src={se} alt="" className={'w-[85%] h-auto'} />
                    <h4 className={'text-5xl mb-[1%] font-semibold'}>Platform Sponsor</h4>
                    <img src={hack2skill} alt="" className={'w-[40%] '} />
                    <h4 className={'text-5xl font-bold'}>Sponsors</h4>
                    <div className="flex flex-col justify-center gap-10">
                        <div className="flex flex-row flex-wrap justify-center gap-10">
                            <img src={require("../images/cblogo.png")} alt="" className={'justify-self-center self-center w-[45%]'} />
                            <img src={require("../images/zsec.webp")} alt="" className={'justify-self-center self-center w-[40%]'} />
                            {/* <img src={require("../images/Evepaper.png")} alt="" className={'justify-self-center self-center w-[45%]'} /> */}
                        </div>
                        {/* <img src={require("../images/zsec.webp")} alt="" className={'justify-self-center self-center w-[40%]'} /> */}
                    </div>

                </div>
            </div>

        </div>
    );
}
export default Sponsors;