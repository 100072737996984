import React from "react";
import "../Styles/TeamCard.css";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function TeamCard({
  img,
  name,
  designation,
  quote,
  linkedin,
}) {
  return (
    <div className="TeamCard-card">
      <div className="TeamCard-img-container">
        <img className="TeamCardImage" src={img} alt={name} />
      </div>
      <div className="text-[24px] font-semibold tracking-tight">{name}</div>
      <br></br>
      <p>{designation}</p>
      <p>{quote}</p>
      <div className="TeamCard-icons">
        <a href={linkedin}>
          <LinkedInIcon fontSize="large"/>
        </a>
      </div>
    </div>
  );
}
