import React, { Fragment, useState } from "react";
import { hackathon_events } from "../utility/timeline_hackathon";
import { ideathon_events } from "../utility/timeline_ideathon";

const Timeline = () => {

    const scheduleTypes = [
        {
            scheduleId: "hackathon", scheduleName: "Hackathon"
        },
        {
            scheduleId: "ideathon", scheduleName: "Ideathon"
        }
    ];

    const [showSchedule, setShowSchedule] = useState("hackathon");

    return (
        <div className={'flex flex-col justify-center max-w-[80%] m-auto mt-10'}>
            <h1 className="text-white text-7xl text-center mt-10 mb-5">Schedule</h1>
            <div className="flex flex-row text-center justify-center gap-10">
                {scheduleTypes.map((val, idx) => (  
                    <button
                        key={idx}
                        onClick={() => setShowSchedule(val.scheduleId)}
                        className={`text-3xl p-3 rounded transition-all ease-in-out ${showSchedule === val.scheduleId ? 'bg-white text-[#120266]' : 'bg-[#120266] text-white'}`}
                    >
                        {val.scheduleName}
                    </button>
                ))}
            </div>

            <div className='flex flex-col gap-y-3 w-full my-4'>
                <Circle />
                {
                    showSchedule === "hackathon" ? (
                        hackathon_events.map((event, key) => {
                            return <Fragment key={key}>
                                <div className='grid grid-cols-[1fr_auto_1fr] gap-x-2 items-center mx-auto'>
                                    {event.direction === 'left' ? (
                                        <EventCard heading={event.heading} subHeading={event.subheading} />
                                    ) : (
                                        <div></div>
                                    )}
                                    <Pillar />
                                    {event.direction === 'right' ? (
                                        <EventCard heading={event.heading} subHeading={event.subheading} />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {key < (hackathon_events.length - 1) && <Circle />}
                            </Fragment>;
                        })
                    ) :
                        ideathon_events.map((event, key) => {
                            return <Fragment key={key}>
                                <div className='grid grid-cols-[1fr_auto_1fr] gap-x-2 items-center mx-auto'>
                                    {event.direction === 'left' ? (
                                        <EventCard heading={event.heading} subHeading={event.subheading} />
                                    ) : (
                                        <div></div>
                                    )}
                                    <Pillar />
                                    {event.direction === 'right' ? (
                                        <EventCard heading={event.heading} subHeading={event.subheading} />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {key < (hackathon_events.length - 1) && <Circle />}
                            </Fragment>;
                        })

                }

                <Circle />
            </div>
        </div>
    );
};
const Circle = () => {
    return (<div className='bg-gradient-to-r from-b  lue-500 to-teal-500 rounded-full w-4 h-4 mx-auto'></div>);
};
const Pillar = () => {
    return (<div className='bg-gradient-to-b from-blue-500 to-teal-500 rounded-t-full rounded-b-full w-2 h-full bg-blue-500 mx-auto'></div>);
};
const EventCard = ({ heading, subHeading }) => {
    return (<div classNAme='transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-2x1 flex flex-col gap-y-2 border shadow-md rounded-x1 p-4'>
        <div className='text-white font-bold text-lg border-b'>{heading}</div>
        <div className='text-sm- text-white'>{heading}</div>
    </div>);
};
export default Timeline;